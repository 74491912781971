import PageTitle2 from '@components/pageTitle2';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO_BY_FIELD } from '@schemas/queries/users';
import { useAppContext } from '@utils/context/appContext';
import { useRouter } from 'next/router';

type Props = {
  close?: Function,
  setOpenUpdateCompany?: Function,
  setShowModal?: Function,
};

export default function PostOptions(props: Props) {
  const { close, setOpenUpdateCompany } = props;
  const { isLogin } = useAppContext(),
    router = useRouter();
  const { data } = useQuery(
    GET_USER_INFO_BY_FIELD(['type', 'statusProfileCompany']),
    {
      skip: !isLogin,
    },
  );

  const isCompany = data?.user.type === 'Company',
    isApproved = data?.user.statusProfileCompany === 'approved';

  return (
    <div className="flex flex-col w-full bg-white">
      <PageTitle2 title="Đăng tin" onRowClick={close} />
      <div className="flex flex-col p-16px space-y-3">
        <div
          onClick={() => {
            // if (isLogin) {
            router.push('/dang-tin-rao').then();
            // } else {
            //   setShowModal(true);
            // }
          }}
          className="flex w-full items-center space-x-1">
          <img
            src="/svg/postHouseIcon.svg"
            className="w-24px h-24px"
            alt="icon"
          />
          <p className="heading4">Đăng tin rao</p>
        </div>
        <div
          onClick={() => {
            router.push('/dang-tin-can-mua-can-thue').then();
          }}
          className="flex w-full items-center space-x-1">
          <img
            src="/svg/postNeedIcon.svg"
            className="w-24px h-24px"
            alt="icon"
          />
          <p className="heading4">Đăng tin cần mua - cần thuê</p>
        </div>
        {isCompany && (
          <a
            onClick={() => {
              if (!isApproved) {
                setOpenUpdateCompany(true);
                close();
              }
            }}
            className="flex w-full items-center space-x-1"
            href={isApproved ? '/404' : '#'}>
            <img
              src="/svg/postNeedIcon.svg"
              className="w-24px h-24px"
              alt="icon"
            />
            <p className="heading4">Đăng tin dự án</p>
          </a>
        )}
      </div>
    </div>
  );
}

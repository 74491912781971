import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

const AddFile = dynamic(() => import('@public/svg/add_file.svg'));
import DrawerWrapper from '@components/DrawerWrapper';
import PostOptions from '@components/BottomMenu/components/PostOptions';
const UpdateCompanyProfileMobile = dynamic(() =>
  import('@components/modals/UpgradeCompany/UpdateCompanyProfileMobile'),
);

type PostProps = {
  setShowModal?: Function,
};
export default function Post({ setShowModal }: PostProps) {
  const [openDrawer, setOpenDrawer] = useState(false),
    [openUpdateCompany, setOpenUpdateCompany] = useState(false);
  useEffect(() => {
    openDrawer
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [openDrawer]);
  return (
    <>
      <div
        onClick={() => setOpenDrawer(true)}
        className="w-70px h-70px rounded-rd50 bg-gray-200 absolute z-20 -top-12px p-4px">
        <div className="w-full h-full bg-blue-main rounded-rd50 flex items-center justify-center flex-col">
          <AddFile className="w-24px h-24px" />
          <div className="text-white text-11 font-text mt-4px truncate">
            Đăng tin
          </div>
        </div>
      </div>
      <DrawerWrapper open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <PostOptions
          close={() => setOpenDrawer(false)}
          setOpenUpdateCompany={setOpenUpdateCompany}
          setShowModal={setShowModal}
        />
      </DrawerWrapper>
      <UpdateCompanyProfileMobile
        openModal={openUpdateCompany}
        setOpenModal={setOpenUpdateCompany}
      />
    </>
  );
}

import dynamic from 'next/dynamic';
import Item from '@components/BottomMenu/components/Item';
import Post from '@components/BottomMenu/components/Post';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useChatContext } from '@utils/context/chatContext';
import { useAppContext } from '@utils/context/appContext';
import AuthenticationMobile from '@components/AuthenticationMobile';
import useOpenModalLogin from '@hooks/auth/login/useOpenModalLogin';
import { isMobile } from '@utils/helper';
const HouseMenu = dynamic(() => import('@public/svg/houseMenu.svg'));
const BalanceMenu = dynamic(() => import('@public/svg/balanceMenu.svg'));
const ChatIcon = dynamic(() => import('@public/svg/chatIcon.svg'));
const More = dynamic(() => import('@public/svg/tabMoreIcon.svg'));

export default function BottomMenu() {
  const router = useRouter(),
    { asPath, query } = router;

  const { isLogin } = useAppContext(),
    [showSignUp, setShowSignUp] = useState(false);

  const { showModal, setShowModal, gotoLogin } = useOpenModalLogin();

  useEffect(() => {
    if (!isLogin && !!query?.ref?.length && isMobile) {
      setShowSignUp(true);
    }
  }, [isLogin]);

  return (
    <div className="w-full h-60px bg-white border-t border-gray-300 fixed bottom-0 z-10 flex-row items-center justify-around flex lg:hidden flex-row">
      <Item
        Icon={HouseMenu}
        title="Trang chủ"
        href="/"
        isActive={asPath === '/'}
      />
      <Item
        onClick={() => {
          isLogin ? setShowModal(false) : gotoLogin();
        }}
        href={isLogin ? '/dinh-gia' : '/#'}
        Icon={BalanceMenu}
        title="Định giá"
      />
      <div className="w-70px" />
      <ChatButton isLogin={isLogin} gotoLogin={gotoLogin} />
      <Item
        Icon={More}
        title="Thêm"
        href="/mo-rong"
        isActive={asPath === '/mo-rong'}
      />
      <Post setShowModal={setShowModal} />
      <AuthenticationMobile
        setShowSignUp={setShowSignUp}
        showSignUp={showSignUp}
        showLogin={showModal}
        setShowLogin={setShowModal}
      />
    </div>
  );
}

type ChatButtonProps = {
  gotoLogin?: Function,
  isLogin?: boolean,
};
function ChatButton({ gotoLogin, isLogin }: ChatButtonProps) {
  const { setShowChatMobile } = useChatContext();
  return (
    <>
      <div
        onClick={() => {
          if (isLogin) {
            setShowChatMobile(true);
          } else {
            gotoLogin();
          }
        }}
        className="flex flex-col items-center w-60px">
        <ChatIcon className="w-24px h-24px" fill={'#fff'} />
        <div className={`font-text text-11 text-gray-400 `}>Chat</div>
      </div>
    </>
  );
}

import { ReactComponentElement } from 'react';

type Props = {
  Icon?: ReactComponentElement,
  title?: string,
  href?: string,
  isActive?: boolean,
  onClick?: Function,
};

export default function Item(props: Props) {
  const { Icon, title, href = '/', isActive, onClick = () => {} } = props;
  return (
    <button onClick={onClick}>
      <a href={href} className="flex flex-col items-center w-60px">
        <Icon className="w-24px h-24px" fill={isActive ? '#2459ad' : '#fff'} />
        <div
          className={`font-text text-11 text-gray-400 ${
            isActive && 'text-blue-main font-bold font-textBold'
          }`}>
          {title}
        </div>
      </a>
    </button>
  );
}
